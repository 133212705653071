@import "cornerstone_ui_core/lib/assets/scss/_colors";

//Listing themes below. Variations can be listed below like dark,glossy etc.
$themes: (
  light: (
    backgroundDark: $estee-grey-5,
    backgroundLight: $estee-white-1,
    textDark: $estee-indigo-1,
    textLight: $estee-white-1,
    iconDark: $estee-indigo-1,
    iconLight: $estee-white-1,
    headerDark: $estee-indigo-1,
    headerLight: $estee-indigo-5,
    buttonBgDark: $estee-indigo-1,
    buttonBgLight: $estee-white-1,
    buttonDisabled: $estee-white-3,
    notification: $estee-notification,
    borderDark: $estee-indigo-1,
    borderLight: $estee-grey-3,
    dividerDark: $estee-indigo-1,
    messageSuccess: $estee-success,
    messageWarning: $estee-warn,
    messageDanger: $estee-danger,
    messageInfo: $estee-info,
    messageSuccessBorder: $estee-success-border,
    messageWarningBorder: $estee-warn-border,
    messageDangerBorder: $estee-danger-border,
    messageInfoBorder: $estee-info-border,
    footerLight: $estee-white-1,
    footerBorder: $estee-grey-1,
    tableHeader: $estee-grey-4,
    tableOddRow: $estee-white-1,
    tableEvenRow: $estee-grey-4,
    tableRowBorder: $estee-grey-2,
    callOutBg: $estee-grey-6,
    tableBorder: $estee-grey-7,
    tableOddRow_1: $estee-white-1,
    tableEvenRow_1: $estee-grey-4,
    tableHeader_1: $estee-blue-1,
    cardUpload: $estee-light-blue,
    tableHeaderText_1: $estee-white-2,
    textDropdown: $estee-black-1,
    tableRowHover: $estee-blue-hover,
    tableRowSelection: $blue-highlight,
    rbYellowBackground: $estee-rb-yellow-1,
    rbGreenBackground: $estee-rb-green-1,
    inputDisabledText: $estee-black-1,
    inputDisabledBackground: $estee-grey-06,
    submitButtonBg: $estee-secondary-blue,
    textLabel: $estee-black-1,
    inputEnabledText: $estee-black-1,
    warningButtonColor: $estee-secondary-blue,
    tableOddRow_2: $estee-grey-08,
    tableEvenRow_2: $estee-white-1,
    tableBorder_2: $estee-grey-06,
    tableHeader_2: $estee-white-1,
    textLinkColor: $estee-secondary-blue,
    borderShadow: $estee-bordered-shadow,
    darkYellow: $estee-dark-yellow,
    fontWhite: $estee-grey-09,
    redColor: #ff0000,
    labelViolet: #800080,
    lightYello: #ffff80
  )
);
// Created a color schema or palette if at all required. We would be using the theme alone for time being.
$color-schemes: (
  scheme-default: (
    backgroundColor: $estee-indigo-1
  )
);
// This below mixin is only for generating theming base class. Hence it is here and not in mixins partial-------------->
@mixin customize($arg: theme) {
  $array: $themes;
  $prefix: estee;
  @if ($arg == theme) {
    $array: $themes;
    $prefix: estee;
  } @else {
    $array: $color-schemes;
    $prefix: color;
  }
  @each $theme, $map in $array {
    .#{$prefix}-#{$theme} & {
      $array-map: () !global;
      @each $key, $submap in $map {
        $value: map-get(map-get($array, $theme), "#{$key}");
        $array-map: map-merge(
          $array-map,
          (
            $key: $value
          )
        ) !global;
      }
      @content;
      $array-map: null !global;
    }
  }
}
@function getValue($key) {
  @return map-get($array-map, $key);
}

//Core imports
@import "./core";

//Component imports
@import "cornerstone_ui_core/lib/assets/scss/_buttons";
@import "cornerstone_ui_core/lib/assets/scss/_form";
@import "cornerstone_ui_core/lib/assets/scss/_drawer";
@import "cornerstone_ui_core/lib/assets/scss/_header";
@import "./table";
@import "./modal";
@import "cornerstone_ui_core/lib/assets/scss/_message";
@import "cornerstone_ui_core/lib/assets/scss/_notification";
@import "cornerstone_ui_core/lib/assets/scss/_divider";
@import "cornerstone_ui_core/lib/assets/scss/_dropdown-table";
@import "cornerstone_ui_core/lib/assets/scss/empty";

//Move all color references to theme and split into components/modules. Check sample below
.cornerstone-app {
  height: 100%;
  @include customize(theme) {
    background: getValue(backgroundLight);
  }

  font-size: $estee-body-copy;

  .full-width-inputs {
    .ant-input-number,
    .ant-calendar-picker {
      width: 100% !important;
    }
  }
  .add-margin {
    .ant-table {
      margin: 0 1.3em;
      // width: 100% !important;
    }
  }
  .card-margin {
    .ant-layout-sider-children {
      margin-left: 1.2em;
    }
  }
  .callout {
    // @include customize(theme) {
    //   background: #ccd0dc;
    // }
    border-radius: 5px;
    padding-bottom: 15px;
    padding-top: 15px;
    @include customize(theme) {
      border: 1px solid getValue(tableRowBorder);
    }
  }

  .form-item-highlight {
    .ant-input,
    .ant-form-item-label {
      font-weight: 700;
    }
  }
  .ant-form-item-label > label.ant-form-item-required::after {
    @include customize(theme) {
      color: getValue(messageDangerBorder);
    }
  }

  .footer-content {
    background-color: $estee-white-1;
    height: 65px;
    padding-bottom: 0px;
    padding-top: 15px;
  }

  .footer-card {
    height: 65px;
  }
  // style for adding margin top to tristate switch
  .hdpgcT {
    margin-top: 10px;
  }
  .footer-card .ant-card-body {
    padding-bottom: 0px;
    padding-top: 15px;
  }

  div.a {
    line-height: 12px;
  }

  .back-button {
    left: 0px;
    position: relative;
    top: 0px;
  }

  .content-header {
    margin: 24px 16px;
    min-height: 280;
    padding-left: 30px;
    position: "relative";
  }

  .card-header {
    @include customize(theme) {
      background-color: getValue(headerDark);
    }
    h5 {
      @include customize(theme) {
        color: getValue(textLight);
      }
    }
  }

  .card-table {
    @include customize(theme) {
      background-color: getValue(backgroundDark);
    }
  }

  .pHeader > .ant-card {
    @include customize(theme) {
      background-color: getValue(textDark);
    }
  }

  .ant-card-wider-padding .ant-card-body {
    padding: 15px 32px;
  }

  //plus icon centering
  .action {
    i.anticon.anticon {
      font-size: $estee-icon-font-size;
    }
  }
  i.anticon.anticon-left,
  i.anticon.anticon-right {
    font-size: 14px;
  }
  i.anticon.anticon-left.anticon svg,
  i.anticon.anticon-right.anticon svg {
    margin-bottom: 5px !important;
  }
  .ant-pagination-item-active a {
    margin-right: 2px;
  }
  i.anticon.anticon-plus {
    font-size: $estee-icon-font-size;
  }
  i.anticon.anticon-eye {
    font-size: $estee-icon-font-size;
    padding-bottom: 3px;
  }
  .parentList {
    margin-left: -1.5%;
  }

  .description {
    font-size: $estee-body-active-header;
    font-weight: 700;
    line-height: 36px;
  }
  .ant-page-header-back {
    margin-top: 5px;
    margin-bottom: 5px;
  }
  .ant-btn-primary[disabled] {
    color: #333;
  }
  .ant-btn.ant-btn-primary.ant-btn-circle.fab-add {
    width: 40px;
  }
  .ant-btn.ant-btn-primary.ant-btn-circle.add-btn {
    width: 40px;
  }
  .ant-btn.ant-btn-primary.ant-btn-circle.add-btn:disabled {
    width: 40px;
    opacity: 0.5;
  }
  @media only screen and (max-width: 766px) {
    .ant-btn.ant-btn-primary.ant-btn-circle.add-btn {
      position: fixed;
      bottom: 20px;
      z-index: 999;
      right: 50px;
    }
  }

  @media only screen and (min-width: 1024px) and (max-width: 1024px) {
    .dropdown {
      margin-left: 35px;
      margin-right: 12px;
    }

    .ant-btn.ant-btn-primary.ant-btn-circle.fab-add {
      right: 42px;
      position: fixed;
      top: 110px;
      width: 40px;
      height: 40px;
    }
  }

  @media only screen and (min-width: 768px) and (max-width: 768px) {
    .ant-btn.ant-btn-primary.ant-btn-circle.fab-add {
      right: 2px;
      position: fixed;
      top: 110px;
      width: 36px;
      height: 36px;
    }
    .dropdown {
      margin-left: 26px;
      margin-right: 2px;
    }
  }
  .ant-btn:disabled {
    @include customize(theme) {
      background-color: getValue(inputDisabledBackground);
    }
  }
  .ant-radio-button-wrapper {
    font-size: 20px;
    @include customize(theme) {
      color: getValue(textDark);
    }
  }
  .ant-radio-button-wrapper-disabled {
    font-size: 20px;
  }

  .ant-radio-group-solid
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    @include customize(theme) {
      color: getValue(textLight);
    }
  }
  .search-fields {
    margin: 0em 0 0 1.5em;
  }
}

// .ant-radio-button-wrapper {
//   @include customize(theme) {
//   font-size: 20px;
//   color: getValue(borderDark);}
// }
// .ant-radio-button-wrapper-disabled {
//   @include customize(theme) {
//     font-size: 20px;
//     color: getValue(footerLight);}
// }
// .estee-light
//   .cornerstone-app
//   .ant-radio-group-solid
//   .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled),
// .estee-light
//   .ant-radio-group-solid
//   .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {

//     @include customize(theme) {
//       background-color:getValue(borderDark);
//       border-color: getValue(borderDark);

//       color: getValue(footerLight);
//     }
//     }

ul.ant-select-dropdown-menu-vertical
  li:not(.ant-select-dropdown-menu-item-disabled) {
  @include customize(theme) {
    @include font-size($estee-dropdown-font-size);
    color: getValue(textDropdown);
  }
}

.ant-popover-message-title {
  @include customize(theme) {
    color: getValue(textDark);
  }
}
.custom-ant-select-read-only.ant-select-disabled {
  color: inherit;
  font-size: $estee-body-copy;
  .ant-select-arrow {
    display: none;
  }
  .ant-select-selection {
    background-color: inherit;
    border: none;
    cursor: pointer;
  }
}
.ant-select-selection__rendered {
  border-radius: 0px;
}
.ant-form-item-control.has-success {
  border-radius: 0px;
}

.height-initial {
  height: initial;
}

.ant-layout {
  @include customize(theme) {
    background: getValue(backgroundLight);
  }
}

.ant-select-disabled {
  @include customize(theme) {
    background-color: getValue(inputDisabledBackground);
  }
}
.ant-select-disabled .ant-select-selection {
  @include customize(theme) {
    background-color: getValue(inputDisabledBackground);
  }
}

.ant-switch-disabled {
  .ant-switch-inner {
    @include customize(theme) {
      color: $white;
    }
  }
}
.ant-switch-enabled {
  .ant-switch-inner {
    @include customize(theme) {
      color: #fff;
    }
  }
}

.ant-menu-item .anticon,
.ant-menu-submenu-title .anticon {
  margin-top: 0.832rem;
  float: left;
}
.ant-menu-submenu-title,
.ant-menu-inline .ant-menu-item {
  @include customize(theme) {
    color: getValue(textDropdown);
  }
}

.menu-inlineMode .ant-menu-inline .ant-menu-item {
  white-space: normal !important;
  height: auto !important;
  margin-bottom: 24px;
  line-height: 1.5;
}

.ant-dropdown-menu-item,
.ant-dropdown-menu-submenu-title {
  @include customize(theme) {
    color: getValue(textDropdown);
  }
}

#app-base-layout .sidebar-overlay-dark ul li.ant-menu-item {
  @include customize(theme) {
    color: getValue(textLight);
  }
}
.shadow-sm {
  @include customize(theme) {
    box-shadow: 0 4px 8px -2px rgba(9, 30, 66, 0.25),
      0 0 1px 0 rgba(9, 30, 66, 0.31);
  }
}
.shadow-md {
  @include customize(theme) {
    box-shadow: 0 8px 16px -4px rgba(9, 30, 66, 0.25),
      0 0 1px 0 rgba(9, 30, 66, 0.31);
  }
}
.shadow-lg {
  @include customize(theme) {
    box-shadow: 0 12px 24px -6px rgba(9, 30, 66, 0.25),
      0 0 1px 0 rgba(9, 30, 66, 0.31);
  }
}
.shadow-xl {
  @include customize(theme) {
    box-shadow: 0 16px 32px -8px rgba(9, 30, 66, 0.25),
      0 0 1px 0 rgba(9, 30, 66, 0.31);
  }
}
.shadow-2xl {
  @include customize(theme) {
    box-shadow: 0 20px 40px -10px rgba(9, 30, 66, 0.25),
      0 0 1px 0 rgba(9, 30, 66, 0.31);
  }
}
.shadow-inner {
  @include customize(theme) {
    box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
  }
}
.shadow-outline {
  @include customize(theme) {
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5);
  }
}
.shadow-none {
  @include customize(theme) {
    box-shadow: none;
  }
}

.ant-table-thead {
  background-color: transparent !important;
  border: 1px solid #f8f8f8 !important;
}
.ant-table-column-sorter {
  top: 32% !important;
}
.ant-table-thead
  > tr
  > th
  .ant-table-column-sorter
  .ant-table-column-sorter-inner-full {
  margin-top: -0.3em !important;
}
// .ant-table-tbody>tr:nth-child(odd) {
//   background-color: #ffffff !important;
// }
.estee-light
  .cornerstone-app
  .ant-table
  .ant-table-tbody
  > tr:not(.obsolete-disabled),
.estee-light .rds .ant-table-tbody > tr:not(.obsolete-disabled) {
  background-color: transparent;
}
.col-float {
  float: right;
}
.button-margin {
  margin-left: 12px;
  margin-bottom: 13px;
  margin-right: 12px;
}
//Toastify
.Toastify__toast {
  min-height: 40px !important;
}
.Toastify__progress-bar {
  background: transparent !important;
}
.Toastify__toast-container {
  font-size: 15px !important;
  font-family: "NotoSans" !important;
}
.Toastify__toast--success {
  @include customize(theme) {
    background: getValue(messageSuccess) !important;
    border: 1px solid #b2cf5c !important;
    color: getValue(textLabel) !important;
  }
}
.Toastify__toast--warning {
  @include customize(theme) {
    background: getValue(messageWarning) !important;
    border: 1px solid #ffb82a !important;
    color: getValue(textLabel) !important;
  }
}
.Toastify__toast--error {
  @include customize(theme) {
    background: getValue(messageDanger) !important;
    border: 1px solid #dc1515 !important;
    color: getValue(textLabel) !important;
  }
}
.Toastify__toast--info {
  @include customize(theme) {
    background: getValue(messageInfo) !important;
    border: 1px solid #94a1e0 !important;
    color: getValue(textLabel) !important;
  }
}
.Toastify__close-button {
  color: #3248b2 !important;
}
.Toastify__close-button:focus {
  outline: none;
}
.Toastify__toast--ConcatenatedMessagesWithNewLine {
  white-space: pre-line;
}
button:focus {
  outline: none !important;
}
.ant-input-group-addon button.ant-input-search-button {
  border: 1px solid #7b7b7b;
  min-width: 40px;
  height: 40px;
  font-size: 15px;
  padding: 3px;
}
.clear-padding {
  padding-top: 0 !important;
}

.button-icon {
  width: 38px;
  height: 38px;
  border: solid #cfe1eb 1px;
  border-radius: 5px;
  padding: 5px 5px;
  margin-left: 5px;
  cursor: pointer;
  svg {
    width: 24px;
    height: 24px;
  }
}
.button-icon:hover {
  background-color: #cfe1eb;
}
.ant-table-fixed-header .ant-table-scroll {
  overflow: auto !important;
}

.ant-table-empty.ant-table-fixed-header .ant-table-scroll .ant-table-header {
  margin-bottom: 0px !important;
  overflow: visible !important;
}
.promise-modal .ant-modal-body {
  padding-top: 0px !important;
}
.clear-padding-header {
  .ant-page-header-ghost {
    padding-left: 0px !important;
  }
}
.container-fluid-pad {
  padding-right: 17px !important;
  padding-left: 17px !important;
}
.container-fluid-pad-free {
  .container-fluid {
    padding-right: 0px !important;
  }
}

.shrunk-blowup-columns {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  & > .ant-col {
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1 0 auto;
    & > .left_div {
      height: 100%;
      height: 750px;
      overflow-y: auto;
      & > .ant-card-body {
        overflow-y: auto;
      }
      .ant-card {
        border: 1px solid #e8e8e8;
        box-shadow: 0 6px 10px rgba(0, 0, 0, 0.08), 0 0 6px rgba(0, 0, 0, 0.05);
        transition: 0.3s transform cubic-bezier(0.155, 1.105, 0.295, 1.12),
          0.3s box-shadow,
          0.3s -webkit-transform cubic-bezier(0.155, 1.105, 0.295, 1.12);
        // box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.1);
      }
      .ant-card-head {
        font-size: 14px;
        // padding-right: 14px;
      }
      .ant-card-body {
        padding: 10px 10px 10px 10px;
      }
    }
  }
  .ant-card.left_div_inner_card.ant-card-bordered {
    margin-bottom: 10px;
  }
  .ant-card-bordered {
    border: none;
  }
  .ant-card.left_div_inner_card {
    .ant-card-head {
      font-size: 12px;
      color: #006197;
    }
  }
  .right_div {
    padding: 15px 15px 20px 20px;
    border: 1px solid #e8e8e8;
    box-shadow: 1px 4px 8px 1px rgba(0, 0, 0, 0.08);
    margin: 0px 10px 0px 10px;
    border-radius: 1px;
    .ant-table {
      margin: 0em;
      width: 100% !important;
    }
  }
}

.app-base-header {
  z-index: 4;
}

#app-base-layout .el-page-header .ant-page-header-heading-title,
#app-base-layout .el-page-header .ant-page-header-title-view-title {
  color: $estee-black-1 !important;
}

ul.ant-menu.ant-menu-light.ant-menu-root.ant-menu-vertical
  li.ant-menu-item:not(:hover) {
  @include customize(theme) {
    color: getValue(textDropdown);
  }
}

.ant-form-item-label label {
  font-weight: 500;
}
.ant-input {
  height: 30px;
}
.ant-select-selection--single {
  height: 30px;
}
.ant-select-selection__rendered {
  line-height: 30px;
}
.ant-form-item {
  margin-bottom: 10px !important;
}
.ant-select-arrow > * {
  line-height: 1.3;
}

#search-box i.anticon.anticon-search {
  vertical-align: 0.1em !important;
}
.ant-input-group-addon button.ant-input-search-button {
  height: 30px;
}
.ant-select-selection--multiple {
  min-height: 30px;
  height: auto;
}
.ant-input-number {
  height: 30px;
}
div.react-grid-Cell--frozen {
  z-index: inherit;
}
.ant-input-number-input {
  height: 30px;
}
.ant-page-header-ghost {
  padding-left: 0px !important;
}
.ant-table-tbody i.anticon.anticon-more.ant-dropdown-trigger {
  vertical-align: 0em;
}
td.ant-table-row-cell-ellipsis {
  svg.ant-dropdown-trigger,
  i.anticon.ant-dropdown-trigger svg {
    height: 1.5em;
    width: 1.5em;
  }
}
.ant-select-selection--multiple .ant-select-selection__rendered > ul > li {
  height: 25px;
  margin-top: 2px;
  line-height: 23px;
  i.anticon.anticon-close.ant-select-remove-icon {
    vertical-align: 0.1em;
  }
}

.react-grid-Cell,
.react-grid-Cell * {
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}

//Modal style overrides

.cornerstone-app {
  .ant-modal-wrap {
    overflow: initial;
  }
}

.ant-modal-wrap {
  overflow: initial;
}

.lm {
  overflow: auto !important;
}
.promise-modal {
  @include customize(theme) {
    top: 64px;
  }

  font-size: $estee-body-copy;

  &.formModal {
    .ant-form-explain {
      @include error-message-styles;
    }
  }

  .ant-table {
    @include font-styles;
  }

  .ant-modal-content {
    @include font-styles;
    & .ant-modal-body {
      @include font-styles;
    }
  }

  .ant-modal-header {
    @include customize(theme) {
      // background-color: getValue(headerDark);
      font: black;
    }
  }

  .ant-modal-title {
    @include customize(theme) {
      // color: getValue(textLight);
    }
  }
  .ant-modal-close-x {
    z-index: 1;
  }
  .ant-modal-close-x {
    @include customize(theme) {
      // color: getValue(iconLight);
    }
  }
  .ant-form-item-required::before {
    line-height: 0;
  }

  .ant-btn.ant-btn-primary.ant-btn-circle {
    // right: 50px;
    // position: absolute;
    // top: -14px;
    width: 40px;
    height: 40px;
  }

  .ant-col-11.ant-col-offset-2:nth-of-type(odd) {
    margin-left: 0;
  }

  .ant-modal-body {
    max-height: 80vh;
    overflow-y: auto;
  }

  .ant-modal-body .cornerstone-app {
    background: none;

    //extra for scroll
    padding: 12px 12px 12px 12px;
    font-size: $estee-body-copy;
    line-height: 1.5;
    word-wrap: break-word;
    overflow-y: auto;
  }

  .ant-tag.ant-tag-has-color {
    float: right;
  }

  .ant-form-item-required::before {
    content: none;
  }
  .ant-form-item-required::after {
    content: "*";
    @include customize(theme) {
      color: getValue(messageDangerBorder);
    }
  }

  .fixedFooter {
    position: absolute;
    bottom: 0;
    border-top: solid 1px;
    @include customize(theme) {
      border-top-color: getValue(footerBorder);
    }
    width: 90%;
    overflow: hidden;
    height: 55px;
    padding-top: 10px;
    z-index: 999;
    @include customize(theme) {
      background: getValue(footerLight);
    }
  }

  .ant-table-body {
    min-height: 0px !important;
  }
  .promise-modal.closeWhite .ant-modal-title,
  .promise-modal.closeWhite .anticon-close {
    color: #fff;
  }
  .promise-modal.closeWhite .ant-modal-header {
    background-color: #061777;
  }

  &.simple-form-modal .SimpleFormModal__Form {
    margin-bottom: 40px;
  }
  &.simple-form-modal .SimpleFormModal__Footer.fixedFooter.ant-row {
    width: 85%;
    z-index: 1;
  }
}

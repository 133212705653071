.cornerstone-app {
  .ant-table-thead {
    @include customize(theme) {
      font-weight: bolder;
    }
    tr {
      th.ant-table-column-has-actions.ant-table-column-has-filters.ant-table-column-has-sorters.react-resizable,
      th.ant-table-column-has-actions.ant-table-column-has-filters.ant-table-column-has-sorters.react-resizable:hover,
      th.ant-table-column-has-actions.ant-table-column-has-sorters:hover
        .anticon-filter,
      th.ant-table-column-has-actions.ant-table-column-has-sorters:hover
        .ant-table-filter-icon {
        @include customize(theme) {
          // background: getValue(tableHeader_1);
        }
      }
    }
  }

  .ant-table {
    @include customize(theme) {
      color: getValue(textDark);
      background-color: getValue(backgroundLight);
    }
    // font-family: "Inter UI";
    font-family: $estee-noto-font-family;
    font-size: inherit;
    font-style: normal;
    font-weight: 400;
    font-feature-settings: normal;
  }

  .ant-table-tbody > tr {
    border-bottom: 1px solid;
    @include customize(theme) {
      border-bottom-color: getValue(tableRowBorder);
    }
  }
  .ant-table-thead > tr > th {
    @include customize(theme) {
      color: getValue(textDark);
    }
    .ant-table-column-sorter {
      .ant-table-column-sorter-inner {
        @include customize(theme) {
          // color: getValue(textLight);
        }
      }
    }
  }
  .ant-table-tbody > tr:nth-child(odd) {
    @include customize(theme) {
      background-color: getValue(tableOddRow);
    }
  }
  .ant-table-tbody > tr:nth-child(even) {
    @include customize(theme) {
      background-color: getValue(tableEvenRow);
    }
  }
  .ant-table-fixed-left table,
  .ant-table-fixed-right table {
    @include customize(theme) {
      color: getValue(textDark);
    }
  }

  .ant-table-small
    > .ant-table-content
    > .ant-table-header
    > table
    > .ant-table-thead
    > tr
    > th,
  .ant-table-small
    > .ant-table-content
    > .ant-table-body
    > table
    > .ant-table-thead
    > tr
    > th,
  .ant-table-small
    > .ant-table-content
    > .ant-table-scroll
    > .ant-table-header
    > table
    > .ant-table-thead
    > tr
    > th,
  .ant-table-small
    > .ant-table-content
    > .ant-table-scroll
    > .ant-table-body
    > table
    > .ant-table-thead
    > tr
    > th,
  .ant-table-small
    > .ant-table-content
    > .ant-table-fixed-left
    > .ant-table-header
    > table
    > .ant-table-thead
    > tr
    > th,
  .ant-table-small
    > .ant-table-content
    > .ant-table-fixed-right
    > .ant-table-header
    > table
    > .ant-table-thead
    > tr
    > th,
  .ant-table-small
    > .ant-table-content
    > .ant-table-fixed-left
    > .ant-table-body-outer
    > .ant-table-body-inner
    > table
    > .ant-table-thead
    > tr
    > th,
  .ant-table-small
    > .ant-table-content
    > .ant-table-fixed-right
    > .ant-table-body-outer
    > .ant-table-body-inner
    > table
    > .ant-table-thead
    > tr
    > th,
  .ant-table-small
    > .ant-table-content
    > .ant-table-header
    > table
    > .ant-table-tbody
    > tr
    > td,
  .ant-table-small
    > .ant-table-content
    > .ant-table-body
    > table
    > .ant-table-tbody
    > tr
    > td,
  .ant-table-small
    > .ant-table-content
    > .ant-table-scroll
    > .ant-table-header
    > table
    > .ant-table-tbody
    > tr
    > td,
  .ant-table-small
    > .ant-table-content
    > .ant-table-scroll
    > .ant-table-body
    > table
    > .ant-table-tbody
    > tr
    > td,
  .ant-table-small
    > .ant-table-content
    > .ant-table-fixed-left
    > .ant-table-header
    > table
    > .ant-table-tbody
    > tr
    > td,
  .ant-table-small
    > .ant-table-content
    > .ant-table-fixed-right
    > .ant-table-header
    > table
    > .ant-table-tbody
    > tr
    > td,
  .ant-table-small
    > .ant-table-content
    > .ant-table-fixed-left
    > .ant-table-body-outer
    > .ant-table-body-inner
    > table
    > .ant-table-tbody
    > tr
    > td,
  .ant-table-small
    > .ant-table-content
    > .ant-table-fixed-right
    > .ant-table-body-outer
    > .ant-table-body-inner
    > table
    > .ant-table-tbody
    > tr
    > td {
    padding: 5px 8px;
    border: solid 1px;
    @include customize(theme) {
      border-color: getValue(tableBorder);
    }
  }

  //global styling for ant tables
  .ant-table {
    @include customize(theme) {
      color: getValue(tableRowText_1);
    }

    .ant-table-thead > tr > th {
      @include customize(theme) {
        // background-color: getValue(tableHeader_1);
        color: getValue(tableHeaderText_1);
      }
    }

    .ant-table-tbody > tr:nth-child(odd) {
      @include customize(theme) {
        background-color: getValue(tableOddRow_1);
      }
    }

    .ant-table-tbody > tr:nth-child(even) {
      @include customize(theme) {
        background-color: getValue(tableEvenRow);
      }
    }

    .ant-table-tbody > tr:nth-child(even).selected-row,
    .ant-table-tbody > tr:nth-child(odd).selected-row {
      @include customize(theme) {
        background-color: getValue(tableRowSelection) !important;
      }
    }

    font-size: 12px;
    td {
      .ant-btn {
        background: none;
        border: none;
      }
    }
  }

  .ant-table-pagination.ant-pagination {
    margin-right: 36px;
  }

  .ant-table-small > .ant-table-content > .ant-table-body {
    margin: 0;
    .ant-table-small > .ant-table-content > .ant-table-body {
      margin: 0;
    }
  }

  .ant-dropdown-menu-item .ant-table-wrapper {
    max-width: 100vw;
  }

  .ant-table-wrapper.y-scroll {
    overflow-y: scroll;
    max-height: 260px;
  }

  .white-space-normal {
    white-space: normal;
  }
}
.app {
  //global styling for reactstrap tables
  .app-table.react-bootstrap-table {
    .table {
      @include customize(theme) {
        color: getValue(tableRowText_1);
      }

      thead > tr > th {
        @include customize(theme) {
          // background-color: getValue(tableHeader_1);
          color: getValue(tableHeaderText_1);
        }
      }
      tbody > tr:nth-child(odd) {
        @include customize(theme) {
          background-color: getValue(tableOddRow_1);
        }
      }

      tbody > tr:nth-child(even) {
        @include customize(theme) {
          background-color: getValue(tableEvenRow_1);
        }
      }

      font-size: 12px;
      td {
        padding: 0px 5px;
        line-height: 35px;
        font-size: 12px;
        border: solid 1px;
        @include customize(theme) {
          border-color: getValue(tableBorder);
        }
      }
      thead th {
        font-size: 12px;
        font-weight: normal;
        @include customize(theme) {
          background-color: getValue(headerDark);
          color: getValue(textLight);
        }
        border: solid 1px;
        @include customize(theme) {
          border-color: getValue(tableBorder);
        }
      }
    }
    .table tbody > tr:nth-child(even):hover,
    .table tbody > tr:nth-child(odd):hover {
      @include customize(theme) {
        background-color: getValue(tableRowHover);
        color: getValue(textDark);
      }
    }
  }

  .estee-light
    .app
    .app-table.react-bootstrap-table
    .table
    tbody
    > tr:nth-child(even)
    .react-bootstrap-table-pagination-list-hidden {
    display: none;
  }

  //global styling for ant tables
  .ant-table {
    @include customize(theme) {
      color: getValue(tableRowText_1);
    }

    .ant-table-thead > tr > th {
      @include customize(theme) {
        // background-color: getValue(tableHeader_1);
        color: getValue(tableHeaderText_1);
      }
      border: solid 1px;
      @include customize(theme) {
        border-color: getValue(tableBorder);
      }
    }

    .ant-table-tbody > tr:nth-child(odd) {
      @include customize(theme) {
        background-color: getValue(tableOddRow_1);
      }
    }

    .ant-table-tbody > tr:nth-child(even) {
      @include customize(theme) {
        background-color: getValue(tableEvenRow_1);
      }
    }

    .ant-table-tbody > tr:nth-child(even).selected-row,
    .ant-table-tbody > tr:nth-child(odd).selected-row {
      @include customize(theme) {
        background-color: getValue(tableRowSelection);
      }
    }

    .ant-table-tbody > tr:hover td {
      @include customize(theme) {
        background-color: getValue(tableRowHover);
      }
    }

    //hover over columns with sorters
    .ant-table-thead
      > tr
      > th.ant-table-column-has-actions.ant-table-column-has-sorters:hover,
    .ant-table-thead
      > tr
      > th.ant-table-column-has-actions.ant-table-column-has-filters:hover
      .anticon-filter:hover {
      @include customize(theme) {
        // background-color: getValue(tableHeader_1);
        color: getValue(tableHeaderText_1);
      }
    }
    .ant-table-thead
      > tr
      > th.ant-table-column-has-actions.ant-table-column-has-sorters:hover
      .anticon-filter {
      background: inherit;
    }

    font-size: 12px;
    //padding inside the table cells
    td {
      padding: 0px 5px;
      line-height: 35px;
      border: solid 1px;
      @include customize(theme) {
        border-color: getValue(tableBorder);
      }
      .ant-btn {
        background: none;
        border: none;
      }
      // .ant-btn:active, .ant-btn.active{
      //   color: #061777;
      // }
    }
  }
}

// @import "node_modules/cornerstone_ui_core/lib/assets/scss/index.scss";
@import "node_modules/cornerstone_ui_core/lib/assets/scss/_fonts.scss";
@import "node_modules/cornerstone_ui_core/lib/assets/scss/bootstrap/_bootstrap.scss";
@import "node_modules/cornerstone_ui_core/lib/assets/scss/_theme.scss";
@import "node_modules/cornerstone_ui_core/lib/assets/scss/_table.scss";
@import "node_modules/cornerstone_ui_core/lib/assets/scss/_layout.scss";
@import "node_modules/cornerstone_ui_core/lib/assets/scss/_modal.scss";

//Core imports
@import "./theme";

body {
  @include customize(theme) {
    background-color: getValue(backgroundDark);
  }
}
